@font-face {
  font-family: 'Gorga Grotesque Bold';
  src: url('../../assets/fonts/GorgaGrotesque-Bold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Gorga Grotesque Bold Italic';
  src: url('../../assets/fonts/GorgaGrotesque-Bolditalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Gorga Grotesque Italic';
  src: url('../../assets/fonts/GorgaGrotesque-Italic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Gorga Grotesque Light';
  src: url('../../assets/fonts/GorgaGrotesque-Light.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Gorga Grotesque Light Italic';
  src: url('../../assets/fonts/GorgaGrotesque-LightItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Gorga Grotesque Regular';
  src: url('../../assets/fonts/GorgaGrotesque-Regular.woff') format('woff');
  font-display: swap;
}
